import React, { useState } from "react";
import Separator from "@/pages/separator";
import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";
import logoDark from "../../public/images/logo/logo-dark.png";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";

const Footer = () => {

   const [formData, setFormData] = useState({
    email: '',
  });

const [formErrors, setFormErrors] = useState({
    email: '',
  });


  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
    // Reset email error when user types in the email field
    if (id === 'email') {
      setFormErrors({
        ...formErrors,
        email: '',
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
     // Email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email.match(emailPattern)) {
      setFormErrors({
        ...formErrors,
        email: 'Please enter a valid email',
      });
      return;
    }
    try {
      const response = await fetch('/api/sendNewsletter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Subscription successful!');
        setFormData({   
          email: '',
        });
      } else {
        alert('Subscription Failed.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('An error occurred while sending the message.');
    }
  };

  return (
    <>
      <footer className="rainbow-footer footer-style-default footer-style-3 position-relative">
        <Separator top={true} />
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center mb--30">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="rainbow-footer-widget text-center">
                  <div className="logo">
                    <Link href="/">
                      <Image
                        className="logo-light"
                        src={logo}
                        width={201}
                        height={35}
                        alt="Corporate Logo"
                      />
                      <Image
                        className="logo-dark"
                        src={logoDark}
                        width={201}
                        height={35}
                        alt="Corporate Logo"
                      />
                    </Link>
                  </div>
                  <p className="b1 text-center mt--20 mb--0">
                    CyberAgric empowers farmers with AI-driven insights, recommendations, and virtual assistance, ultimately contributing to improved crop health, increased yields, and sustainable agricultural practices.
                  </p>
                </div>
              </div>
            </div>
            <div className="separator-animated animated-true mt--50 mb--50"></div>
            {FooterData &&
              FooterData.footer.map((data, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="rainbow-footer-widget">
                      <h4 className="title">{data.contact}</h4>
                      <div className="inner">
                        <h6 className="subtitle mb--10">Email: {data.email}</h6>
                        <h6 className="subtitle mb--10">Phone: {data.phone}</h6>
                        <h6 className="subtitle">Address: {data.address}</h6>
                       
                      </div>
                    </div>
                  </div>
                  
                   <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="rainbow-footer-widget">
                      <h4 className="title">{data.title}</h4>
                      <div className="inner">
                        <h6 className="subtitle">{data.desc}</h6>
                        <form className="newsletter-form" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                                id="email"
                              type="email"
                              placeholder="Enter Your Email Here"
                              value={formData.email} onChange={handleChange} 
                                className={formErrors.email ? 'p-holder__error' : ''}
                                required
                            />
                            {formErrors.email && <span className="p-holder__error">{formErrors.email}</span>}
                            <button
                              className="btn-default bg-solid-primary"
                              type="submit"
                            >
                              <i className="feather-arrow-right"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* <SingleFooter data={data.services} /> */}
                  
                  {/* <SingleFooter data={data.company} />  */}
                  <SingleFooter data={data.solutions} openInNewTab={true}/> 
                  <SingleFooter data={data.products} />
                </div>
              ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
